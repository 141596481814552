var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-list-dt"},[(!_vm.dTransactionListError)?[_c('f-data-table',_vm._b({staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"hidden-columns":_vm.hiddenColumns,"items":_vm.dItems,"mobile-view":_vm.cMobileView,"disable-infinite-scroll":!_vm.dHasNext,"loading":_vm.cLoading,"f-card-off":_vm.fCardOff,"first-m-v-column-width":"5","infinite-scroll":"","fixed-header":""},on:{"fetch-more":_vm.onFetchMore},scopedSlots:_vm._u([{key:"column-hash",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{name: 'transaction-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{name: 'transaction-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-timestamp",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{includeSeconds: true}}})],1)]):[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{includeSeconds: true}}})]]}},{key:"column-address",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-from",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-to",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{name: 'address-detail', params: {id: value}},"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[(_vm.addressCol)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.addressCol,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})]],2)]):[(_vm.addressCol)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.addressCol,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})]]]}},{key:"subrow",fn:function(ref){
var item = ref.item;
var columns = ref.columns;
var visibleColumnsNum = ref.visibleColumnsNum;
var style = ref.style;
var tabindex = ref.tabindex;
var dtItemId = ref.dtItemId;
var mobileView = ref.mobileView;
return [(!mobileView)?[(_vm.filterApprovals(item.transaction.tokenTransactions).length > 0)?_c('tr',{staticClass:"subrow",style:(style),attrs:{"tabindex":tabindex,"data-dt-item-id":dtItemId}},[_c('td',{staticClass:"tokentxstd",attrs:{"colspan":visibleColumnsNum}},[_c('div',{staticClass:"tokentxs"},[_c('token-transactions-list',{attrs:{"token-transactions":_vm.filterApprovals(item.transaction.tokenTransactions),"address":_vm.addressCol}})],1)])]):_vm._e()]:[(_vm.filterApprovals(item.transaction.tokenTransactions).length > 0)?_c('details',{staticClass:"tokentxs"},[_c('summary',[_vm._v("Details")]),_c('token-transactions-list',{attrs:{"token-transactions":_vm.filterApprovals(item.transaction.tokenTransactions),"address":_vm.addressCol}})],1):_vm._e()]]}}],null,false,572190875)},'f-data-table',Object.assign({}, _vm.$attrs, _vm.$props),false))]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.dTransactionListError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }