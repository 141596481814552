<template>
    <div class="view-home narrow-container">
        <div class="row back_top">
            <div class="col-8 offset-2 col-10-lg offset-1-lg col-12-sm no-offset-sm">
                <div class="logo">
                    <img src="../assets/image/Logo2.png" alt="">
                </div>
                <f-search-box></f-search-box>
            </div>
        </div>
        <div class="back">
            <img src="../assets/image/home_back.png" alt="">
        </div>
        <div class="row row-2-cols-lg no-collapse equal-height">
            <div class="col">
                <router-link :to="{name: 'blocks'}" class="no-effect">
                    <f-card class="home-block" hover>
                        <img src="../assets/image/tuer4.png" alt="">
                        <h3 class="h3">{{ $t('view_home.blocks') }} <icon data="@/assets/svg/angle-right.svg" color="#999"></icon></h3>
                        <div class="num">
                            <animated-number
                                :value="chainState.blocks | formatHexToInt"
                                :formatValue="formatNum"
                                :duration="numAnimationDuration"
                            />
                        </div>
                        <button class="block_button" >view more</button>
                    </f-card>
                </router-link>
            </div>
            <div class="col">
                <router-link :to="{name: 'staking'}" class="no-effect">
                    <f-card class="home-block" hover>
                        <img src="../assets/image/tuer2.png" alt="">
                        <h3 class="h3">{{ $t('view_home.validators') }} <icon data="@/assets/svg/angle-right.svg" color="#999"></icon></h3>
                        <div class="num">
                            <animated-number
                                :value="chainState.validators | formatHexToInt"
                                :formatValue="formatNum"
                                :duration="numAnimationDuration"
                            />
                        </div>
                        <button class="block_button" >view more</button>
                    </f-card>
                </router-link>
            </div>
            <div class="col">
                <f-card class="home-block">
                    <img src="../assets/image/tuer.png" alt="">
                    <h3 class="h3">{{ $t('view_home.accounts') }}</h3>
                    <div class="num">
                        <!-- chainState.accounts | formatHexToInt  -->
                        <animated-number
                            :value="40732"
                            :formatValue="formatNum"
                            :duration="numAnimationDuration"
                        />
                    </div>
                    <button class="block_button" >view more</button>
                </f-card>
            </div>
            <!-- <div class="col">
                <router-link :to="{name: 'transactions'}" class="no-effect">
                    <f-card class="home-block" hover>
                        <img src="../assets/image/tuer3.png" alt="">
                        <h3 class="h3">{{ $t('view_home.transactions') }} <icon data="@/assets/svg/angle-right.svg" color="#999"></icon></h3>
                        <div class="num">
                            <animated-number
                                :value="chainState.transactions | formatHexToInt"
                                :formatValue="formatNum"
                                :duration="numAnimationDuration"
                            />
                        </div>
                        <button class="block_button" >view more</button>
                    </f-card>
                </router-link>
            </div> -->
        </div>
        <div class="row Latestsyt">
            <div class="col">
                <h2 class="h3">最新区块</h2>
            </div>
            <div class="col">
                <h2 class="h3">最新交易</h2>
            </div>
        </div>
        <div class="row row-2-cols-lg equal-height ">
            <div class="col">
                <f-card class="half-padding">
                    <home-block-list :items="blocksData" :hidden-columns="['time', 'fee']" :items-per-page="10" class="home-table" />
                    <router-link :to="{name: 'blocks'}" class="btn small secondary" style="width: 100%;">
                        View all blocks
                    </router-link>
                </f-card>
            </div>
            <div class="col">
                <f-card class="half-padding">
                    <home-transaction-list :items="blocksData" :hidden-columns="['gasUsed']" :items-per-page="5" class="home-table" />
                    <router-link :to="{name: 'transactions'}" class="btn small secondary" style="width: 100%;">
                        View all transactions
                    </router-link>
                </f-card>
            </div>
        </div>
        <div class="row equal-height mat-5">
            <div class="col">
                <f-card class="half-padding">
                    <div class="txvolumes_label">
                        <h2 class="h3" id="txv">日交易量</h2>
                        <f-listbox v-model="txVolumesResolution" :focus-item-on-focus="true" :data="txVolumesResolutions" labeled-by="txv" horizontal />
                    </div>
                    <transaction-volumes :resolution="txVolumesResolution" />
                </f-card>
            </div>
        </div>
    </div>
</template>

<script>
    import FCard from "../components/core/FCard/FCard.vue";
    import FSearchBox from "../components/FSearchBox.vue";
    import gql from 'graphql-tag';
    import HomeBlockList from "@/data-tables/HomeBlockList.vue";
    import HomeTransactionList from "@/data-tables/HomeTransactionList.vue";
    import AnimatedNumber from "animated-number-vue";
    import {pollingMixin} from "@/mixins/polling.js";
    import TransactionVolumes from "@/components/TransactionVolumes.vue";
    import FListbox from "@/components/core/FListbox/FListbox.vue";
    import {formatNumberByLocale} from "@/filters.js";

    export default {
        mixins: [pollingMixin],

        components: {
            FListbox,
            TransactionVolumes,
            HomeTransactionList,
            HomeBlockList,
            FCard,
            FSearchBox,
            AnimatedNumber
        },

        data() {
            return {
                blocksData: [],
                txVolumesResolution: '1m',
                txVolumesResolutions: [
                    {
                        label: '2周',
                        value: '14d',
                    },
                    {
                        label: '1个月',
                        value: '1m',
                    },
                    {
                        label: '1个季度',
                        value: '3m',
                    },
                ],
                numAnimationDuration: 750,
                chainState: {
                    blocks: 0,
                    validators: 0,
                    accounts: 0,
                    transactions: 0,
                },
            }
        },

        created() {
            this.updateChainState();
        },

        mounted() {
            this._polling.start(
                'update-net-state',
                () => {
                    this.updateChainState();
                },
                3300
            );
        },

        methods: {
            async updateChainState() {
                this.chainState = {...await this.fetchState()};

            },

            /**
             * @returns {Promise<Object>}
             */
            async fetchState() {
                const data = await this.$apollo.query({
                    query: gql`
                        query State {
                            state {
                                blocks
                                transactions
                                accounts
                                validators
                                sfcLockingEnabled
                                sealedEpoch {
                                    id
                                    totalSupply
                                    baseRewardPerSecond
                                }
                            }
                        }
                    `,
                    fetchPolicy: 'network-only',
                });

                return data.data.state || {};
            },

            formatNum(_num) {
                return formatNumberByLocale(parseInt(_num), 0);
            }
        }
    }
</script>

<style lang="scss">
    .view-home {
        .logo{
            width: 140px;
            margin: 40px auto;
            height: 100px;
            position: relative;
            z-index: 1;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .f-search-box {
            width: 100%;
            padding: 8px 0 40px 0;
            position: relative;
            z-index: 1;
            //padding: 64px 0;
            margin-bottom: 147px;
        }
        .back{
            width: 100%;
            height: 753px;
            position: absolute;
            top: 94px;
            left: 0px;
            z-index: 0;
            img{
                width: 100%;
                height: 100%;
            }
        }

        .home-block {
            --f-card-padding: 20px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            // align-content: center;
            justify-content: center;
            box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.09);
            min-height: 338px;
            img{
                width: 49px;
                height: 48px;
            }
            h3 {
                //text-align: center;
                margin-top: 25px;
                margin-bottom: 38px;
            }

            .num {
                //text-align: center;
                //font-weight: bold;
                font-size: 40.16px;
                margin-bottom: 38px;
            }
            .block_button{
                background: #5F6DE7;
                width: 109px;
                height: 41px;
                border: 0;
                color: #fff;
                border-radius: 4px;
            }
        }
        .Latestsyt{
            margin-top: 48px;
        }
        .home-table {
            margin-bottom: 16px;
            height: 300px;
        }

        .txvolumes_label {
            display: flex;
            flex-wrap: wrap;
            //gap: 16px;
            align-items: center;
            margin-bottom: 4px;

            h2 {
                margin-bottom: 0;
                padding-inline-end: 16px;
            }
        }
    }

    @include media-max($bp-menu) {
        .view-home {
            .f-search-box {
                padding: 32px 0;
            }
        }
    }

    @include media-max($bp-small) {
        .view-home {
            .home-block {
                min-height: 136px;

                h2 {
                    font-size: $fs16;
                }

                .num {
                    font-size: 28px;
                }
            }

            .txvolumes_label {
                //font-size: 14px;
                h2 {
                    margin-bottom: 8px;
                }
            }
        }
    }
</style>
